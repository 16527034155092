<template>
  <v-app>
    <router-view name="dialog"></router-view>
    <router-view></router-view>
  </v-app>
</template>

<script>
import environmentMixin from '@/mixins/environment-mixin.js'
export default {
  name: 'LayoutDefault',
  mixins: [environmentMixin],
  data: () => ({
    //
  })
}
</script>
